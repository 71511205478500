var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('CBox', {
    attrs: {
      "as": "p",
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "d": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": "pointer",
      "color": _vm.colorCoupon
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-box', {
    attrs: {
      "margin-top": "-1px"
    }
  }, [_c('Icon', {
    attrs: {
      "icon": "ri:question-fill",
      "color": _vm.colorCoupon,
      "height": "15",
      "width": "15"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.statusAppliedCoupon === 'failed' ? "Kode kupon tidak sesuai" : "Pelajari lebih lanjut") + " ")], 1), _c('c-drawer', {
    attrs: {
      "is-open": _vm.isOpen,
      "placement": "bottom",
      "on-close": _vm.close,
      "initial-focus-ref": function initialFocusRef() {
        return _vm.$refs.inputInsideModal;
      }
    }
  }, [_c('c-drawer-overlay'), _c('c-drawer-content', {
    attrs: {
      "rounded-top": "12px"
    }
  }, [_c('c-drawer-close-button'), _c('c-drawer-header', {
    attrs: {
      "m": "auto",
      "pb": "0px"
    }
  }, [_c('CImage', {
    attrs: {
      "size": "110px",
      "object-fit": "cover",
      "src": _vm.iconCoupon,
      "alt": "Segun Adebayo"
    }
  })], 1), _c('c-drawer-body', {
    attrs: {
      "text-align": "justify",
      "font-size": "14px",
      "font-weight": "500",
      "color": "#3E3E3E"
    }
  }, [_vm._v(" Kamu dapat menggunakan kode kupon untuk memotong harga pesananmu. Kode kupon dapat kamu temukan di halaman promosi ataupun secara eksklusif didapatkan melalui promosi khusus yang diadakan oleh Dietela. ")]), _c('c-drawer-footer', [_c('c-button', {
    attrs: {
      "font-size": "14px",
      "font-weight": "500",
      "variant-color": "primary",
      "size": "lg",
      "rounded": "1000px",
      "w": "100%"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(" Kembali ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }