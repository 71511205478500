import { render, staticRenderFns } from "./text-change-program.vue?vue&type=template&id=05d0fe52&"
import script from "./text-change-program.vue?vue&type=script&lang=js&"
export * from "./text-change-program.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CImage: require('@chakra-ui/vue').CImage, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalBody: require('@chakra-ui/vue').CModalBody, CButton: require('@chakra-ui/vue').CButton, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
