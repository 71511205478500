<template>
  <c-box
    w="100%"
  >
    <c-text
      :font-size="['16px', '20px']"
      :font-weight="['500', 'Bold']"
      color="#111111"
    >
      Detail Pembayaran
    </c-text>
    <c-divider />
    <c-box
      as="table"
      mx="16px"
      my="8px"
    >
      <c-box as="tr">
        <c-box
          as="th"
          :font-size="['12px', '16px']"
          :font-weight="['400', 'Regular']"
          color="#555555"
          text-align="start"
          w="100%"
        >
          Subtotal
        </c-box>
        <c-box
          as="td"
          :font-size="['14px', '20px']"
          :font-weight="['400', 'Bold']"
          color="#111111"
          text-align="end"
          white-space="nowrap"
        >
          {{ formatCurrency(price) }}
        </c-box>
      </c-box>
      <c-box as="tr">
        <c-box
          as="th"
          :font-size="['12px', '16px']"
          :font-weight="['400', 'Regular']"
          color="#555555"
          text-align="start"
          w="100%"
        >
          Potongan
        </c-box>
        <c-box
          as="td"
          :font-size="['14px', '20px']"
          :font-weight="['400', 'Bold']"
          color="#111111"
          text-align="end"
          white-space="nowrap"
        >
          -{{ formatCurrency(discount) }}
        </c-box>
      </c-box>
    </c-box>
    <c-divider />
    <c-box
      d="flex"
      justify-content="space-between"
      align-items="center"
      mt="20px"
    >
      <c-box
        color="#888888"
        :font-size="['12px', '18px']"
        :font-weight="['400', 'Medium']"
      >
        Total
      </c-box>
      <c-box
        :font-size="['18px', '28px']"
        :font-weight="['700', 'Bold']"
        color="#008C81"
      >
        {{ formatCurrency(finalPrice) }}
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import { CBox, CText, CDivider } from '@chakra-ui/vue'
import { formatCurrency } from '@/utils/format-currency'

export default {
  name: 'ElementCheckOrderInfoPrice',
  components: { CText, CBox, CDivider },
  props: {
    price: {
      required: true,
      type: Number,
    },
    discount: {
      required: true,
      type: Number,
    },
    finalPrice: {
      required: true,
      type: Number,
    },
  },
  emits: ['set-coupon-code', 'handle-click-check-coupon'],
  methods: { formatCurrency },
}
</script>
