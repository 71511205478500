var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['500', 'Bold'],
      "color": "#111111"
    }
  }, [_vm._v(" Detail Pembayaran ")]), _c('c-divider'), _c('c-box', {
    attrs: {
      "as": "table",
      "mx": "16px",
      "my": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "color": "#555555",
      "text-align": "start",
      "w": "100%"
    }
  }, [_vm._v(" Subtotal ")]), _c('c-box', {
    attrs: {
      "as": "td",
      "font-size": ['14px', '20px'],
      "font-weight": ['400', 'Bold'],
      "color": "#111111",
      "text-align": "end",
      "white-space": "nowrap"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.price)) + " ")])], 1), _c('c-box', {
    attrs: {
      "as": "tr"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "th",
      "font-size": ['12px', '16px'],
      "font-weight": ['400', 'Regular'],
      "color": "#555555",
      "text-align": "start",
      "w": "100%"
    }
  }, [_vm._v(" Potongan ")]), _c('c-box', {
    attrs: {
      "as": "td",
      "font-size": ['14px', '20px'],
      "font-weight": ['400', 'Bold'],
      "color": "#111111",
      "text-align": "end",
      "white-space": "nowrap"
    }
  }, [_vm._v(" -" + _vm._s(_vm.formatCurrency(_vm.discount)) + " ")])], 1)], 1), _c('c-divider'), _c('c-box', {
    attrs: {
      "d": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "mt": "20px"
    }
  }, [_c('c-box', {
    attrs: {
      "color": "#888888",
      "font-size": ['12px', '18px'],
      "font-weight": ['400', 'Medium']
    }
  }, [_vm._v(" Total ")]), _c('c-box', {
    attrs: {
      "font-size": ['18px', '28px'],
      "font-weight": ['700', 'Bold'],
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.finalPrice)) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }