import { render, staticRenderFns } from "./learn-more.vue?vue&type=template&id=ac5b72cc&"
import script from "./learn-more.vue?vue&type=script&lang=js&"
export * from "./learn-more.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CBox: require('@chakra-ui/vue').CBox, CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerCloseButton: require('@chakra-ui/vue').CDrawerCloseButton, CImage: require('@chakra-ui/vue').CImage, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CButton: require('@chakra-ui/vue').CButton, CDrawerFooter: require('@chakra-ui/vue').CDrawerFooter, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
