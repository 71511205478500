import { render, staticRenderFns } from "./element-check-order-info-price.vue?vue&type=template&id=4ee69ba0&"
import script from "./element-check-order-info-price.vue?vue&type=script&lang=js&"
export * from "./element-check-order-info-price.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CDivider: require('@chakra-ui/vue').CDivider, CBox: require('@chakra-ui/vue').CBox})
